import { useState, useRef } from 'react'
import { Box, Flex, Stack, Button, Modal, useDisclosure,
  ModalOverlay, ModalContent, ModalHeader, Heading,
  ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'

import { FiDownload, FiMessageCircle } from 'react-icons/fi'
import { FaWindows, FaApple, FaLinux } from 'react-icons/fa'
import { MdCheck, MdClose } from 'react-icons/md'

import { Remarkable } from 'remarkable'

import utils from '../utils'

const TopMenu = ({ eula }) => {
  const [accepted, setAccepted] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const modalRef = useRef()

  const openModal = _ => {
    setAccepted(false)
    onOpen()
  }

  const navigateTo = (platform) => window.location.href = utils.downloadLink('edennetwork', platform)

  const loginModal = <Modal isCentered isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent className='modalContent'>
      <ModalHeader color='white'>Download EdenNetwork</ModalHeader>
      <ModalCloseButton color='white' />
      <ModalBody overflowY='auto' ref={modalRef} className='modalBody' >
        {
          accepted
            ? <Heading align='center' m='2em' as='h4' size='md' color='white'>Please choose your operating system:</Heading>
            : <Box className='markdown' dangerouslySetInnerHTML={{ __html: new Remarkable().render(eula) }} />
        }
        </ModalBody>

      <ModalFooter>
        {
          accepted
            ? <Flex direction='row' w='100%' justify='space-around'>
            <Button leftIcon={<FaWindows />} variant='outline'
              onClick={_ => navigateTo('win')}>
              Windows&nbsp;<small>(64 bit: 7/8/8.1/10/11)</small>
            </Button>
            <Button leftIcon={<FaApple />} variant='outline'
              onClick={_ => navigateTo('mac')}>
              macOS&nbsp;<small>(64 bit Intel: 10.10 Yosemite - 11.0 Big Sur)</small>
            </Button>
            <Button leftIcon={<FaLinux />} variant='outline'
              onClick={_ => navigateTo('nix')}>
              Linux&nbsp;<small>(64 bit)</small>
            </Button>
          </Flex>
          : <Flex direction='row' w='100%' justify='space-around'>
            <Button leftIcon={<MdCheck />} variant='outline' colorScheme='green' onClick={_ => setAccepted(true)}>
              Accept
            </Button>
            <Button leftIcon={<MdClose />} variant='outline' colorScheme='red' onClick={onClose}>
              Close
            </Button>
          </Flex>
        }
      </ModalFooter>
    </ModalContent>
  </Modal>


  return <Box className='topMenu'>
    <Flex justify='flex-end'>
      <Stack direction='row' spacing='1em'>
        <Button colorScheme='green' variant='outline' leftIcon={<FiDownload />} onClick={openModal}>
          Download
        </Button>
        <Button colorScheme='blue' variant='outline' leftIcon={<FiMessageCircle />} onClick={utils.navigateTo(utils.url.board)}>
          Board
        </Button>
      </Stack>
    </Flex>
    {loginModal}
  </Box>
}

export default TopMenu
