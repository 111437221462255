import { Flex, Button } from '@chakra-ui/react'
import { FaFacebook, FaInstagram, FaTelegram, FaDiscord } from 'react-icons/fa'

import utils from '../utils'

export default function SocialButtons() {
  return <Flex flexWrap='wrap' w='60%' maxW='600px' justify='space-between'>
    <Button w='120px' size='sm' color='facebook.800' _hover={{ bg: 'facebook.300' }}
      leftIcon={<FaFacebook />} onClick={utils.navigateTo(utils.url.facebook, true)}>
      Facebook
    </Button>
    <Button w='120px' size='sm' color='instagram.800' _hover={{ bg: 'instagram.300' }}
      leftIcon={<FaInstagram />} onClick={utils.navigateTo(utils.url.instagram, true)}>
      Instagram
    </Button>
    <Button w='120px' size='sm' color='telegram.800' _hover={{ bg: 'telegram.300' }}
      leftIcon={<FaTelegram />} onClick={utils.navigateTo(utils.url.telegram, true)}>
      Telegram
    </Button>
    <Button w='120px' size='sm' color='discord.800' _hover={{ bg: 'discord.300' }}
      leftIcon={<FaDiscord />} onClick={utils.navigateTo(utils.url.discord, true)}>
      Discord
    </Button>
  </Flex>
}
