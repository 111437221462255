import { useState, useEffect } from 'react'
import { useTransition, animated, config } from 'react-spring'
import moment from 'moment'

import day0 from '../images/day_0.jpg'
import day1 from '../images/day_1.jpg'
import day2 from '../images/day_2.jpg'
import day3 from '../images/day_3.jpg'
import night0 from '../images/night_0.jpg'
import night1 from '../images/night_1.jpg'
import night2 from '../images/night_2.jpg'
import night3 from '../images/night_3.jpg'

const slides = [
  { id: 0, type: 'day', image: day0 },
  { id: 1, type: 'day', image: day1 },
  { id: 2, type: 'day', image: day2 },
  { id: 3, type: 'day', image: day3 },
  { id: 5, type: 'night', image: night0 },
  { id: 6, type: 'night', image: night1 },
  { id: 7, type: 'night', image: night2 },
  { id: 8, type: 'night', image: night3 }
]

const delay = 10000

const momentDayFrom = moment('08:30:00', 'hh:mm:ss')
const momentDayTo = moment('18:30:00', 'hh:mm:ss')

const getNextSlide = _ => {
  const filterType = moment().isBetween(momentDayFrom, momentDayTo) ? 'day' : 'night'
  const momentSlides = slides.filter(s => s.type === filterType)
  return momentSlides[Math.floor(Math.random() * (momentSlides.length - 1))]
}

const BackgroundSlideshow = _ => {
  const [slide, setSlide] = useState(getNextSlide())

  useEffect(_ => {
    const timer = setInterval(_ => setSlide(getNextSlide()), delay)
    return __ => clearInterval(timer)
  }, [])

  const BlackLayer = _ => <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      zIndex: -1,
      background: 'rgba(0,0,0,0.5)'
    }} />

  const BackgroundStyle = (props, item) => {
    return {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: -2,
      ...props,
      backgroundImage: `url(${item.image})`
    }
  }

  const transitions = useTransition(slide, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  })

  return <>
    {transitions((props, item) => <animated.div key={`${item.type}${item.id}`} style={BackgroundStyle(props, item)} />)}
    <BlackLayer />
  </>
}

export default BackgroundSlideshow
