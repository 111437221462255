import { extendTheme } from '@chakra-ui/react'

export default extendTheme({
  fonts: {
    body: 'Montserrat, sans-serif',
    heading: 'Montserrat, sans-serif',
    mono: 'Inconsolata, monospace'
  },
  colors: {
    facebook: {
      300: '#6482C0',
      800: '#FFFFFF'
    },
    telegram: {
      300: '#7AC1E4',
      800: '#FFFFFF'
    },
    discord: {
      300: '#5a71c2',
      800: '#FFFFFF'

    },
    instagram: {
      300: '#df6c57',
      800: '#FFFFFF'
    }
  }
})
