import { useState, useEffect } from 'react'
import { Box } from '@chakra-ui/react'
import { Remarkable } from 'remarkable'

import utils from '../utils'

const md = new Remarkable({
  html: true,
  xhtmlOut: true,
  breaks: true,
  typographer: true,
  quotes: '“”‘’'
})

const Privacy = _ => {
  const [eula, setEula] = useState('Loading...')

  useEffect(_ => {
    fetch(utils.url.privacy)
      .then(r => r.text())
      .then(r => setEula(r))
  }, [])

  return <Box className='box markdown' w='75%' dangerouslySetInnerHTML={{ __html: md.render(eula) }} />
}

export default Privacy
