import { useState, useEffect } from 'react'
import { ChakraProvider, DarkMode } from '@chakra-ui/react'
import { BrowserRouter, Switch, Route, Link as RouterLink } from 'react-router-dom'
import { Box, Stack, Image, Spacer, Link } from '@chakra-ui/react'

import './styles/EdenNetwork.scss'
import './styles/TopMenu.scss'
import './styles/Markdown.scss'

import theme from './theme'
import utils from './utils'

import BackgroundSlideshow from './components/BackgroundSlideshow'
import SocialButtons from './components/SocialButtons'
import Footer from './components/Footer'
import TopMenu from './components/TopMenu'

import Home from './components/Home'
import EULA from './components/EULA'
import Privacy from './components/Privacy'

import imageTitle from './images/title.png'

const EdenNetwork = _ => {
  const [eula, setEula] = useState('Loading...')

  useEffect(_ => {
    fetch(utils.url.eula)
      .then(r => r.text())
      .then(r => setEula(r))
  }, [])

  return <BrowserRouter>
    <ChakraProvider theme={theme}>
      <DarkMode>
        <BackgroundSlideshow />
        <Box>
          <TopMenu eula={eula} />
          <Stack align='center' spacing='1.5em' marginTop='1.5em'>
            <Link as={RouterLink} to='/' style={{ maxWidth: '45em' }} >
              <Image src={imageTitle} alt='EdenNetwork' className='title' />
            </Link>
            <Spacer margin='1em' />
            <Switch>
              <Route exact path="/eula">
                <EULA eula={eula} />
              </Route>
              <Route exact path="/privacy">
                <Privacy />
              </Route>
              <Route path="*">
                <Home />
              </Route>
            </Switch>
            <Spacer margin='2em' />
            <SocialButtons />
            <Spacer margin='5em' />
            <Footer />
            <Spacer margin='5em' />
          </Stack>
        </Box>
      </DarkMode>
    </ChakraProvider>
  </BrowserRouter>
}

export default EdenNetwork
