const schema = 'https:'

const baseURL = `${schema}//eden.network`
const downloadsURI = '/launcher/updates'

const extensions = {
  'win': '.exe',
  'mac': '.dmg',
  'nix': '.run'
}

const utils = {
  navigateTo: (url, external) => _ => external ? window.open(url) : window.location.href = url,
  downloadLink: (file, os) => `${baseURL}${downloadsURI}/${file}${extensions[os]}`,
  url: {
    eula: `${baseURL}/launcher/eula.md`,
    privacy: `${baseURL}/launcher/privacy.md`,
    sso: `${baseURL}/api/sso`,
    board: `${schema}//board.eden.network`,
    facebook: `${schema}//fb.com/edennetworkitalia`,
    instagram: `${schema}//instagram.com/edennetworkitalia`,
    telegram: `${schema}//t.me/edennetworkgroup`,
    discord: `${schema}//discord.io/edennetworkitalia`
  }
}

export default utils
