import { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Stack, Heading, Text, useDisclosure,
Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
ModalBody, ModalFooter, Flex, Button, Icon, useToast,
InputGroup, Input, InputLeftElement, InputRightElement } from '@chakra-ui/react'

import { MdClose, MdChevronRight, MdPerson, MdVpnKey, MdVisibility, MdVisibilityOff } from 'react-icons/md'

import utils from '../utils'

const parseQueryString = string => {
  return string.slice(1).split('&')
  .map(queryParam => {
    let kvp = queryParam.split('=')
    return { key: kvp[0], value: kvp[1] }
  })
  .reduce((query, kvp) => {
    query[kvp.key] = kvp.value
    return query
  }, {})
}

const Home = _ => {
  const location = useLocation()
  const toast = useToast()
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLogging, setLogging] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const openLogin = useCallback(_ => {
    setUsername('')
    setPassword('')
    setLogging(false)
    onOpen()
  }, [onOpen])

  useEffect(_ => {
    const search = parseQueryString(location.search)
    if (search.sso && search.sig) openLogin()
  }, [location.search, openLogin])

  const viewPassword = _ => {
    setPasswordVisible(true)
    setTimeout(_ => setPasswordVisible(false), 5000)
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') submitLogin()
  }

  const submitLogin = async _ => {
    if (isLogging || username.length < 3 || password.length < 3) return
    setLogging(true)
    try {
      const res = await fetch(utils.url.sso, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...parseQueryString(location.search),
          username,
          password
        })
      })
      const loginResult = await res.json()
      utils.navigateTo(loginResult.redirect)()
    } catch (e) {
      toast({
        title: 'Login Failed!',
        description: 'Wrong username or password',
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
    
    setLogging(false)
  }

  const inputBorder = 'solid 1px rgba(160, 174, 192, 0.3)'
  const loginModal = <Modal isCentered isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader color='white'>EdenNetwork Login</ModalHeader>
      <ModalCloseButton color='white'/>
      <ModalBody>
        <Stack>
          <InputGroup border={inputBorder} borderRadius='.5em'>
            <InputLeftElement children={<Icon color='white' as={MdPerson} />} />
            <Input color='white' border='0' type='text' placeholder='Username' value={username} onKeyPress={handleKeyPress} onChange={e => setUsername(e.target.value)} />
          </InputGroup>
          <InputGroup border={inputBorder} borderRadius='.5em'>
            <InputLeftElement children={<Icon color='white' as={MdVpnKey} />} />
            <Input color='white' border='0' type={passwordVisible ? 'text' : 'password'} placeholder='Password' value={password} onKeyPress={handleKeyPress} onChange={e => setPassword(e.target.value)} />
            <InputRightElement children={<Icon color='white' as={passwordVisible ? MdVisibilityOff : MdVisibility} />} onClick={viewPassword} />
          </InputGroup>
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Flex direction='row' w='100%' justify='space-between'>
          <Button leftIcon={<MdClose />} colorScheme='red' onClick={onClose}>
            Cancel
          </Button>
          <Button rightIcon={<MdChevronRight />} colorScheme='green' onClick={submitLogin}
            disabled={isLogging || username.length < 3 || password.length < 3} isLoading={isLogging}>
            Login
          </Button>
        </Flex>
      </ModalFooter>
    </ModalContent>
  </Modal>

  return <>
    <Heading as='h3' size='lg' className='subtitle'>
      Connecting Players
    </Heading>
    <Box className='box'>
      <Text>
        Una lunga attesa.<br />
        Un grande ritorno.<br />
        <br />
        Preparatevi a sbarcare di nuovo su EdenCraft!<br />
        Lo storico server italiano &egrave; pronto ad accogliere nuovamente tutti gli appassionati di Minecraft con la sua storia, un PvP completamente rivisitato e mod scritte su misura!
      </Text>
    </Box>
    {loginModal}
  </>
}

export default Home
